<template>
  <div class="main-content">
    <h3 class="marginBottom20">创建</h3>
    <avue-form ref="form"
               v-model="form"
               v-loading="loading"
               :option="formOption"
               @submit="handleSubmit">
      <template slot="businessType"><div>{{ form.businessType === 1 ? '二手房交易' : form.businessType === 2 ? '房屋租赁' : '/' }}</div></template>
      <template slot="houseCode"><div>{{ form.houseCode }}</div></template>
      <template slot="houseName"><div>{{ form.houseName }}</div></template>
      <template slot="brokerCode"><div>{{ form.brokerCode }}</div></template>
      <template slot="brokerName"><div>{{ form.brokerName }}</div></template>
      <template slot="partyAPhone"><div>{{ form.partyAPhone }}</div></template>
      <template slot="partyARole"><div>{{ form.partyARole === 1 ? '卖方' : form.partyARole === 2 ? '业主' : '/' }}</div></template>
      <template slot="partyAName"><div>{{ form.partyAName }}</div></template>
      <template slot="partyACode"><div>{{ form.partyACode }}</div></template>
      <template slot="partyBPhone">
        <div class="flex">
          <div class="width_30 marginRight20">
            <el-input v-model="form.partyBPhone" type="text" maxlength="11"
                      placeholder="请输入 乙方手机号" clearable show-word-limit
                      onkeyup="this.value=this.value.replace(/^(0+)|\D+/g,'')"
                      @blur="changeBlur($event,'partyBPhone')">
            </el-input>
          </div>
          <el-button type="primary" size="mini" :disabled="!partyBPhoneType" @click="handleVerification(form.partyBPhone)">验证</el-button>
        </div>
      </template>
      <template slot="partyBRole"><div>{{ form.partyBRole === 1 ? '买方' : form.partyBRole === 2 ? '租户' : '/' }}</div></template>
      <template slot="partyBName">
        <el-button type="text" v-if="isValType && form.partyBName">{{ form.partyBName }}</el-button>
        <el-button type="text" style="color: #f78989" v-if="isValType && !form.partyBName">查无此用户</el-button>
        <div v-if="!isValType"></div>
      </template>
      <template slot="partyBCode">
        <el-button type="text" v-if="isValType && form.partyBCode">{{ form.partyBCode }}</el-button>
        <el-button type="text" style="color: #F56C6C" v-if="isValType && !form.partyBCode">查无此用户</el-button>
        <div v-if="!isValType"></div>
      </template>

      <template slot="intermediaryContractName">
        <div class="width_30">
          <el-select v-model="form.intermediaryContractName" placeholder="请选择">
            <el-option
                v-for="item in form.intermediaryList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
      </template>
      <template slot="transactContractName">
        <div class="width_30">
          <el-select v-model="form.transactContractName" placeholder="请选择">
            <el-option
                v-for="item in form.signList"
                :key="item.id"
                :label="item.name"
                :value="item.id">
            </el-option>
          </el-select>
        </div>
      </template>
      <template slot="signValidity">
        <div class="width_30">
          <el-input v-model="form.signValidity" type="text"
                    placeholder="请输入 签约有效期" clearable
                    onkeyup="this.value=this.value.replace(/^(0+)|\D+/g,'')"
                    @blur="changeBlur($event,'signValidity',9999)">
            <template #append><span>天</span></template>
          </el-input>
        </div>
      </template>
      <template slot="ownerPayServiceFee"><div>{{ form.ownerPayServiceFee }} %</div></template>
      <template slot="tenantPayServiceFee"><div>{{ form.tenantPayServiceFee }} %</div></template>
      <template slot="menuForm">
        <el-button icon="el-icon-close" @click="handleBack">返 回</el-button>
      </template>
    </avue-form>
  </div>
</template>

<script>
import {signView, signCommit, signPhone} from "@/api/house/tenancy"
export default {
  name: "detail",
  data(){
    return{
      loading: false,
      partyBPhoneType: false,
      isValType: false,
      isValid: 0,
      form: {},
      formOption: {
        submitText: '保存',
        emptyBtn: false,
        labelWidth: 170,
        span: 24,
        column: [
          {
            label: '业务类型',
            prop: 'businessType'
          },
          {
            label: '房源号',
            prop: 'houseCode'
          },
          {
            label: '房源名称',
            prop: 'houseName'
          },
          {
            label: '认证经纪人ID',
            prop: 'brokerCode'
          },
          {
            label: '认证经纪人',
            prop: 'brokerName'
          },
          {
            label: '甲方手机号',
            prop: 'partyAPhone'
          },
          {
            label: '甲方角色',
            prop: 'partyARole'
          },
          {
            label: '甲方姓名',
            prop: 'partyAName'
          },
          {
            label: '甲方用户ID',
            prop: 'partyACode'
          },
          {
            label: '乙方手机号',
            prop: 'partyBPhone',
            rules: [{
              required: true,
              message: "请输入乙方手机号",
              trigger: "blur"
            },{
              validator: (rule, value, callback) => {
                if(!/^1[3456789]\d{9}$/.test(value)){
                  callback(new Error('请输入正确的手机号'));
                  this.isValType = false;
                  this.form.partyBName = "";
                  this.form.partyBCode = "";
                  this.partyBPhoneType = false;
                }else{
                  this.partyBPhoneType = true;
                  callback();
                }
              },
              trigger: 'blur'
            }]
          },
          {
            label: '乙方角色',
            prop: 'partyBRole'
          },
          {
            label: '乙方姓名',
            prop: 'partyBName'
          },
          {
            label: '乙方用户ID',
            prop: 'partyBCode'
          },
          {
            label: '选择居间合同',
            prop: 'intermediaryContractName',
            rules: [{
              required: true,
              message: "请选择居间合同",
              trigger: "blur"
            }]
          },
          {
            label: '选择签约合同',
            prop: 'transactContractName',
            rules: [{
              required: true,
              message: "请选择签约合同",
              trigger: "blur"
            }]
          },
          {
            label: '签约有效期',
            prop: 'signValidity',
            rules: [{
              required: true,
              message: "请输入签约有效期",
              trigger: "blur"
            }]
          },
          {
            label: '是否线上支付佣金',
            prop: 'isOnlinePayCommission',
            type: 'radio',
            dicData: [
              { label: '是', value: 1 },
              { label: '否', value: 2 }
            ],
            rules: [{
              required: true,
              message: "请选择是否线上支付服务费",
              trigger: "blur"
            }]
          },
          {
            label: '业主支付服务费',
            prop: 'ownerPayServiceFee',
            labelWidth: 250
          },
          {
            label: '租户支付服务费',
            prop: 'tenantPayServiceFee',
            labelWidth: 250
          }
        ]
      }
    }
  },
  async mounted() {
    this.loading = true;
    await this.getView();
  },
  methods: {
    async getView(){
      const res = await signView({ id: this.$route.query.id });
      // console.log(res)
      this.loading = false;
      if(res.code === 200){
        this.form = res.data;
      }else{
        this.$router.go(-1)
      }
    },
    handleBack(){
      this.$router.go(-1)
    },
    // 验证手机号
    async handleVerification(row){
      // console.log(row)
      await this.signPhone(row)
    },
    async signPhone(phone){
      const res = await signPhone({ phone:phone });
      // console.log(res)
      if(res.code === 200){
        this.isValType = true;
        this.isValid = res.data.isValid;
        this.form.partyBName = res.data.partyBName;
        this.form.partyBCode = res.data.partyBCode;
      }else{
        this.isValType = false;
        this.$message.error(res.msg)
      }
    },
    /* 提交 */
    handleSubmit(form,done){
      // console.log(form)
      if(!this.isValType){
        this.$message.warning('请验证手机号');
        return done()
      }
      if(this.isValid === 3){
        this.$message.warning('乙方未认证');
        return done()
      }
      if(form.partyACert === 2){
        this.$message.warning('甲方未认证');
        return done()
      }
      // console.log('phone',form.partyBPhone)
      // console.log('intermediaryContractId',form.intermediaryContractName)
      // console.log('transactContractId',form.transactContractName)
      // console.log('signValidity',form.signValidity)
      // console.log('isOnlinePayServiceFee',form.isOnlinePayServiceFee)
      signCommit({
        id: this.$route.query.id,
        phone: form.partyBPhone,
        intermediaryContractId: form.intermediaryContractName,
        transactContractId: form.transactContractName,
        signValidity: form.signValidity,
        isOnlinePayCommission: form.isOnlinePayCommission,
      }).then(res=>{
        if(res.code === 200){
          this.$message.success('保存成功');
          this.$router.go(-1)
        }else{
          this.$message.error(res.msg)
          done();
        }
      }).catch(()=> done())
    },
    // 限制输入框
    changeBlur(e,text,max){
      // console.log(e.target.value,text)
      // console.log(isNaN(e.target.value))
      const value = e.target.value;
      if(value){
        if(isNaN(e.target.value)){
          e.target.value = ''
          this.form[text] = ''
        }else{
          if(max){
            if(Number(value) > Number(max)){
              this.form[text] = Number(max)
            }else{
              this.form[text] = Number(value)
            }
          }else{
            this.form[text] = value
          }
        }
      }else{
        e.target.value = '';
        this.form[text] = ''
      }
    },
  }
}
</script>

<style scoped>

</style>